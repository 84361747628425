import React from "react";
import { Text } from "@chakra-ui/react";

const SuperAdminTierPopoverLabel = ({ type, color, usageAmount }) => {
  return (
    <Text
      textTransform="capitalize"
      fontWeight="bold"
      fontSize=".875em"
      color={color}
    >
      {type} Usage: {usageAmount}
    </Text>
  );
};

export default SuperAdminTierPopoverLabel;

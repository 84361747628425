import React from "react";
import { Popover, Portal } from "@chakra-ui/react";
import { useUserStatusContext } from "src/contexts/users/userStatus";
import { USAGE_TRACKING_THRESHOLD_MAP } from "..";
import { UsageSummaryContent } from "./UsageSummaryContent";
import { UsageSummaryLabelWrapper } from "./UsageSummaryLabelWrapper";
import { UsageSummaryPopupWrapper } from "./UsageSummaryPopupWrapper";
import isNull from "lodash/isNull";

export const UsageSummaryWrapper = ({ type, progressValue, usage, limit }) => {
  const {
    userStatusData: { isFreeUser }
  } = useUserStatusContext();
  const isSuperAdmin = isNull(limit);

  // Round into percentage format
  const percentageValue = progressValue.toFixed();

  const _getThresholdColor = (threshold, isFreeUser) => {
    switch (threshold) {
      case USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE:
        return "tertiary";

      case USAGE_TRACKING_THRESHOLD_MAP.WARNING:
        return "mandala.yellow.500";

      case USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED:
        return isFreeUser ? "mandala.red.700" : "mandala.yellow.500";

      default:
        return "mandala.black.500";
    }
  };

  // This function helps to create threshold map to generate conditions or coloring
  const _getPercentageThreshold = () => {
    if (progressValue < 75) {
      return USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE;
    }

    return progressValue < 100
      ? USAGE_TRACKING_THRESHOLD_MAP.WARNING
      : USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED;
  };

  const percentageThreshold = _getPercentageThreshold();
  const color = _getThresholdColor(percentageThreshold, isFreeUser);
  const showWarningIcon =
    !isSuperAdmin &&
    percentageThreshold !== USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE;

  return (
    <Popover trigger="hover">
      <UsageSummaryContent
        label={type}
        showWarningIcon={showWarningIcon}
        color={color}
        hasProgressBar={!isSuperAdmin}
        progressValue={progressValue}
      >
        <UsageSummaryLabelWrapper
          type={type}
          limit={limit}
          color={color}
          usage={usage}
        />
      </UsageSummaryContent>
      <Portal>
        <UsageSummaryPopupWrapper
          type={type}
          percentageThreshold={percentageThreshold}
          percentageValue={percentageValue}
          usage={usage}
          limit={limit}
        />
      </Portal>
    </Popover>
  );
};

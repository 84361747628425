import { Box, Text } from "@chakra-ui/react";
import { USAGE_TRACKING_MAP, USAGE_TRACKING_THRESHOLD_MAP } from "../..";
import { secondsToMinutes } from "date-fns";
import { getFileSize } from "src/utils/fileSizeConverter";

const ProTierPopoverLabel = ({
  type,
  color,
  usagePercentage,
  usageLeft,
  percentageThreshold,
  diff
}) => {
  const isStorage = type === USAGE_TRACKING_MAP.STORAGE;

  const isQuotaExceeded =
    percentageThreshold === USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED;

  const _generateDiffLabel = () => {
    if (isStorage) {
      return `${getFileSize(diff, {
        fixed: 1
      })} of storage`;
    }

    return `${secondsToMinutes(diff)} minutes of processing`;
  };
  const differenceLabel = _generateDiffLabel();

  return (
    <Box>
      <Text
        textTransform="capitalize"
        fontWeight="bold"
        fontSize=".875em"
        color={color}
      >
        {type} Usage: {usagePercentage}%
      </Text>

      <Text textTransform="capitalize" my=".5em">
        {type}: You still have {usageLeft} {type} usage
      </Text>

      {isQuotaExceeded && (
        <Text mt=".5em" fontSize=".875em">
          Extra {differenceLabel} usage will be billed at the end of this
          billing cycle
        </Text>
      )}
    </Box>
  );
};

export default ProTierPopoverLabel;

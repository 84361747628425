export const USER_TIER = {
  FREE: "FREE",
  PRO: "PRO",
  SUPERADMIN: "SUPERADMIN"
};

export const USER_TIER_LABEL = {
  [USER_TIER.FREE]: "Free",
  [USER_TIER.PRO]: "Pro",
  [USER_TIER.SUPERADMIN]: "Admin"
};

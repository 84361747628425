import { QUERY_KEY_CONSTANTS } from "src/constants/queryKey";
import { useRequest } from "src/contexts/request";
import { checkShouldExecuteQuery } from "src/utils/queryData/checkShouldExecuteQuery";
import { useFetchData } from "src/hooks/utils/useDataQuery";

export const useFetchUserStatusQuery = ({ config }) => {
  const { get, STATION_HOSTNAME } = useRequest();

  const shouldExecuteQuery = checkShouldExecuteQuery(config);

  const _getUserStatus = async () => {
    try {
      const {
        json: { data }
      } = await get(`${STATION_HOSTNAME}/users/status`);

      return {
        tier: data.tier,
        isFreeUser: data.is_free_user,
        isCDPUser: data.is_cdp_user,
        quotaInformation: data.usage_quota,
        isQuotaExceeded: data.is_over_quota,
        daysToReset: data.days_to_reset,
        ...data
      };
    } catch (error) {
      throw error;
    }
  };

  return useFetchData(
    shouldExecuteQuery && [QUERY_KEY_CONSTANTS.GET_USER_STATUS],
    _getUserStatus,
    {
      refetchOnWindowFocus: false,
      ...config
    }
  );
};

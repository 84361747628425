import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

const _Skeleton = ({ isLoaded = false, children, ...props }) => (
  <Skeleton isLoaded={isLoaded} {...props}>
    {children}
  </Skeleton>
);

const _SkeletonCircle = ({ isLoaded = false, children, ...props }) => (
  <SkeletonCircle isLoaded={isLoaded} {...props}>
    {children}
  </SkeletonCircle>
);

const _SkeletonText = ({ isLoaded = false, children, ...props }) => (
  <SkeletonText isLoaded={isLoaded} {...props}>
    {children}
  </SkeletonText>
);
export { _Skeleton, _SkeletonCircle, _SkeletonText };

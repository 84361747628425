import { _Spinner, _SpinnerText } from "./Spinner";
import { _Skeleton, _SkeletonCircle, _SkeletonText } from "./Skeleton";

export const LOADER_TYPE_MAP = {
  SPINNER: "spinner",
  SPINNER_TEXT: "spinner_text",
  SKELETON: "skeleton",
  SKELETON_CIRCLE: "skeleton_circle",
  SKELETON_TEXT: "skeleton_text"
};

export const Loader = ({
  type = LOADER_TYPE_MAP.SPINNER,
  children,
  ...props
}) => {
  const LOADER_TO_COMPONENT_MAP = {
    [LOADER_TYPE_MAP.SPINNER]: _Spinner,
    [LOADER_TYPE_MAP.SPINNER_TEXT]: _SpinnerText,
    [LOADER_TYPE_MAP.SKELETON]: _Skeleton,
    [LOADER_TYPE_MAP.SKELETON_CIRCLE]: _SkeletonCircle,
    [LOADER_TYPE_MAP.SKELETON_TEXT]: _SkeletonText
  };

  const _Loader = LOADER_TO_COMPONENT_MAP[type];

  return <_Loader {...props}>{children}</_Loader>;
};

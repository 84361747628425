import React from "react";
import {
  Box,
  Flex,
  PopoverTrigger,
  Text,
  Icon,
  Progress
} from "@chakra-ui/react";
import { IoMdWarning } from "react-icons/io";

export const UsageSummaryContent = ({
  label,
  showWarningIcon,
  color,
  hasProgressBar = false,
  progressValue,
  children
}) => {
  return (
    <PopoverTrigger>
      <Box w="50%" mx=".5em">
        <Flex align="center" textAlign="center">
          <Text
            textTransform="capitalize"
            color="inherit"
            fontSize=".75em"
            fontWeight="bold"
            pb=".25em"
            mr=".5em"
          >
            {label}
          </Text>
          {showWarningIcon && (
            <Icon color={color} boxSize={3} as={IoMdWarning} />
          )}
        </Flex>
        {hasProgressBar && (
          <Progress value={progressValue} h="6px" trackColor={color} />
        )}
        {children}
      </Box>
    </PopoverTrigger>
  );
};

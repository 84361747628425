import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { USAGE_TRACKING_THRESHOLD_MAP } from "../..";

const FreeTierPopoverLabel = ({
  type,
  usagePercentage,
  percentageThreshold
}) => {
  const _textColorGenerator = () => {
    switch (percentageThreshold) {
      case USAGE_TRACKING_THRESHOLD_MAP.WARNING:
        return "mandala.yellow.700";

      case USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED:
        return "mandala.red.700";

      default:
        return "black";
    }
  };
  const _usageLabelGenerator = () => {
    switch (percentageThreshold) {
      case USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE:
        return `You still have your free ${type} limit`;

      case USAGE_TRACKING_THRESHOLD_MAP.WARNING:
        return `You nearly have reached your free ${type} limit`;

      case USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED:
        return `You have reached your free ${type} limit`;

      default:
        return null;
    }
  };

  const label = _usageLabelGenerator();
  const color = _textColorGenerator();
  return (
    <Box>
      <Text
        textTransform="capitalize"
        fontWeight="bold"
        fontSize=".875em"
        color={color}
      >
        {type} Usage: {usagePercentage}%
      </Text>

      <Text mt="1em" fontSize=".875em">
        {label}
      </Text>
    </Box>
  );
};

export default FreeTierPopoverLabel;

import React from "react";
import { Box, Button, Heading, Image, Text, Link } from "@chakra-ui/react";
import { Modal, ModalFooter } from "src/components/MandalaUI/Modal";

export const LimitQuotaModal = ({ isOpen, onClose = () => {} }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width="40em" pos="relative">
      <Image
        src="/images/quota-limit-exceeded.png"
        objectFit="cover"
        overflow="hidden"
      />
      <Box textAlign="center" mt="1em">
        <Heading variant="h3">You have used up your free quota!</Heading>
        <Text>Are you enjoying the experience of using our product?</Text>
      </Box>

      <ModalFooter>
        <Button
          as={Link}
          variant="ddl-solid"
          href="mailto:contact@delman.io?subject=%5BUpgrade%20Plan%5D%20Please%20enter%20your%20subject"
        >
          Contact our sales!
        </Button>
      </ModalFooter>
    </Modal>
  );
};

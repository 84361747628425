export const checkShouldExecuteQuery = config => {
  // check if enabled is defined in config
  const isEnabledConfigExist = config && "enabled" in config;

  // check if the enabled config is true
  const isEnabled = isEnabledConfigExist && config.enabled;

  // if enabled is defined in the config, return the config.enabled status
  // else if the enabled is not defined in the config, return true (react-query default config)
  return isEnabledConfigExist ? isEnabled : true;
};

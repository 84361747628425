import { Box } from "@chakra-ui/react";
import React from "react";

export const Banner = ({ children, ...props }) => {
  return (
    <Box data-testid="banner" pos="sticky" w="100%" h="2.875em" {...props}>
      {children}
    </Box>
  );
};

import React from "react";
import { Box } from "@chakra-ui/react";
import NavigationBar from "../MandalaUI/NavigationBar";
import { useRouter } from "next/router";
import {
  UserStatusContextProvider,
  useUserStatusContext
} from "src/contexts/users/userStatus";
import { LimitQuotaBanner } from "./Fragments/LimitQuotaBanner";
import { MAIN_LAYOUT, PAGE_WITHOUT_NAVBAR_LIST } from "src/constants/layout";

// This component handles everything inside our app body, hence app content
const AppContent = ({ children, ...props }) => {
  return (
    <Box h="100%" data-testid={props["data-testid"]} {...props}>
      {children}
    </Box>
  );
};

// This component handles the overall page wrapper for ddl-app
const AppShell = ({ children, ...props }) => {
  const router = useRouter();
  const hasNavbar = !PAGE_WITHOUT_NAVBAR_LIST.includes(router.pathname);

  const { showQuotaLimit, isUserInformationStatusFetched } =
    useUserStatusContext();

  const navbarHeight = hasNavbar
    ? MAIN_LAYOUT.NAVBAR_HEIGHT
    : MAIN_LAYOUT.NAVBAR_COLLAPSED_HEIGHT;
  const bannerHeight =
    isUserInformationStatusFetched && showQuotaLimit
      ? MAIN_LAYOUT.BANNER_HEIGHT
      : MAIN_LAYOUT.BANNER_COLLAPSED_HEIGHT;

  const paddingTopContent = `calc(${navbarHeight} + ${bannerHeight})`;

  return (
    <UserStatusContextProvider shouldFetchUserStatus={hasNavbar}>
      <Box data-testid="appshell" h="inherit">
        {hasNavbar && <NavigationBar h={MAIN_LAYOUT.NAVBAR_HEIGHT} />}
        <LimitQuotaBanner
          pos="fixed"
          zIndex={3}
          top={MAIN_LAYOUT.NAVBAR_HEIGHT}
        />
        <AppContent pt={paddingTopContent} {...props}>
          {children}
        </AppContent>
      </Box>
    </UserStatusContextProvider>
  );
};

export default AppShell;

import React from "react";
import { Flex, Text, chakra } from "@chakra-ui/react";

// Provides label for usage tracking
// e.g: the text below the progress bar
export const UsageTrackingSummaryLabel = ({ value, children, color }) => {
  return (
    <Flex fontSize=".675em" mt=".25em" justify="flex-start">
      <Text color="white" fontSize="inherit">
        <chakra.span color={color} fontSize="inherit" mr=".25em">
          {value}
        </chakra.span>
        {children}
      </Text>
    </Flex>
  );
};

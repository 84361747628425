import { PopoverArrow, PopoverContent } from "@chakra-ui/react";
import { secondsToMinutes } from "date-fns";
import { getFileSize } from "src/utils/fileSizeConverter";
import { USAGE_TRACKING_MAP, USAGE_TRACKING_THRESHOLD_MAP } from "..";
import { UsageTrackingPopover } from "./ComponentWrapper/UsageTrackingPopover";
import isNull from "lodash/isNull";
import {
  FreeTierPopoverLabel,
  ProTierPopoverLabel,
  SuperAdminTierPopoverLabel
} from "../Fragments/PopoverLabel";
import { useUserStatusContext } from "src/contexts/users/userStatus";

export const UsageSummaryPopupWrapper = ({
  type,
  percentageThreshold,
  percentageValue,
  usage,
  limit
}) => {
  const {
    userStatusData: { isFreeUser }
  } = useUserStatusContext();
  const isStorage = type === USAGE_TRACKING_MAP.STORAGE;
  const isSuperAdmin = isNull(limit);

  const _generateBorderColor = () => {
    if (
      isFreeUser &&
      percentageThreshold === USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED
    ) {
      return "mandala.red.700";
    }

    if (
      percentageThreshold === USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE
    ) {
      return "tertiary";
    }

    return "mandala.yellow.700";
  };

  const borderColor = _generateBorderColor();
  const color =
    percentageThreshold !== USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE
      ? "mandala.yellow.700"
      : "black";

  const _generateLabelComponent = () => {
    if (isSuperAdmin) {
      return SuperAdminTierPopoverLabel;
    }

    if (isFreeUser) {
      return FreeTierPopoverLabel;
    }

    return ProTierPopoverLabel;
  };

  const PopoverLabel = _generateLabelComponent();

  const remainingDifference = limit - usage;
  const usageLeft = isStorage
    ? getFileSize(remainingDifference, {
        fixed: 1
      })
    : `${secondsToMinutes(remainingDifference)} Mins`;

  const usageAmount = isStorage
    ? getFileSize(usage, {
        fixed: 1
      })
    : `${secondsToMinutes(usage)} Mins`;

  return (
    <PopoverContent
      bg="white"
      borderColor="transparent"
      h="auto"
      w="17.5em"
      boxShadow="md"
      data-testid="popcontent"
    >
      <PopoverArrow />
      <UsageTrackingPopover borderColor={borderColor}>
        <PopoverLabel
          type={type}
          color={color}
          usageLeft={usageLeft}
          usagePercentage={percentageValue}
          usageAmount={usageAmount}
          percentageThreshold={percentageThreshold}
          diff={usage - limit}
        />
      </UsageTrackingPopover>
    </PopoverContent>
  );
};

import React from "react";
import { Flex } from "@chakra-ui/react";
import { useUserStatusContext } from "src/contexts/users/userStatus";
import { Loader, LOADER_TYPE_MAP } from "src/components/MandalaUI/Loader";
import { UsageSummaryTrackerComponent } from "./Fragments/UsageSummaryTrackerComponent";

export const USAGE_TRACKING_THRESHOLD_MAP = {
  FREE_SPACE_AVAILABLE: "FREE_SPACE_AVAILABLE",
  WARNING: "WARNING",
  QUOTA_EXCEEDED: "QUOTA_EXCEEDED"
};

export const USAGE_TRACKING_MAP = {
  STORAGE: "storage",
  PROCESSING: "processing"
};

export const getUsageTrackingThresholdColor = threshold => {
  switch (threshold) {
    case USAGE_TRACKING_THRESHOLD_MAP.FREE_SPACE_AVAILABLE:
      return "tertiary";

    case USAGE_TRACKING_THRESHOLD_MAP.WARNING:
      return "mandala.yellow.500";

    case USAGE_TRACKING_THRESHOLD_MAP.QUOTA_EXCEEDED:
      return "mandala.red.700";

    default:
      return "mandala.black.500";
  }
};

// Prevent HOC from Automatically Generating any input that will cause recursive call
const ComponentLoader = () => (
  <Flex w="100%" justify="space-between">
    <Loader type={LOADER_TYPE_MAP.SKELETON} isLoaded={false} w="45%">
      <Flex h="2em" />
    </Loader>
    <Loader type={LOADER_TYPE_MAP.SKELETON} isLoaded={false} w="45%">
      <Flex h="2em" />
    </Loader>
  </Flex>
);

export const UsageTrackingSection = () => {
  const {
    userStatusData: { quotaInformation },
    isUserInformationStatusLoading
  } = useUserStatusContext();

  return (
    <Flex w="100%" color="white" mr="1em" data-testid="user-tracking-section">
      {isUserInformationStatusLoading ? (
        <ComponentLoader />
      ) : (
        <UsageSummaryTrackerComponent quota={quotaInformation} />
      )}
    </Flex>
  );
};

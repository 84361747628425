import React from "react";
import { useRouter } from "next/router";
import { LimitQuotaModal } from "src/components/MandalaUI/__SharedComponents__/LimitQuotaModal";
import useModal from "src/hooks/useModal";
import { useUserStatus } from "src/hooks/useUserStatus";

const UserStatusContext = React.createContext({});

export const UserStatusContextProvider = ({
  shouldFetchUserStatus,
  children
}) => {
  const { pathname } = useRouter();
  const values = useUserStatus(shouldFetchUserStatus);
  const {
    userStatusData: { isFreeUser, isQuotaExceeded },
    isUserInformationStatusFetched
  } = values;

  // This variable is to track whenever a free user pass their quota
  const showQuotaLimit = isFreeUser && isQuotaExceeded;

  // Open the modal on the main page
  const enableLimitQuotaModal = pathname === "/projects";

  const { isOpen: isLimitModalOpen, toggleModal } = useModal(showQuotaLimit);

  // Handle open toggle modal on every page mount
  React.useEffect(() => {
    if (
      !showQuotaLimit ||
      !enableLimitQuotaModal ||
      (showQuotaLimit && isLimitModalOpen)
    ) {
      return;
    }

    toggleModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showQuotaLimit]);

  return (
    <UserStatusContext.Provider value={{ ...values, showQuotaLimit }}>
      {isUserInformationStatusFetched && enableLimitQuotaModal && (
        <LimitQuotaModal isOpen={isLimitModalOpen} onClose={toggleModal} />
      )}
      {children}
    </UserStatusContext.Provider>
  );
};

export const useUserStatusContext = () => React.useContext(UserStatusContext);

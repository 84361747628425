import React from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
const _Spinner = ({ spinnerProps = {}, children, ...props }) => (
  <Flex w="100%" h="100%" justify="center" align="center" {...props}>
    <Spinner
      size="xl"
      thickness="5px"
      color="mandala.blue.500"
      {...spinnerProps}
    />
    {children}
  </Flex>
);
const _SpinnerText = ({
  spinnerProps = {},
  message = "We are still processing the data. Refreshing automatically.",
  ...props
}) => (
  <Flex
    w="100%"
    h="100%"
    justify="center"
    align="center"
    direction="column"
    {...props}
  >
    <Spinner
      size="xl"
      thickness="10px"
      color="mandala.blue.500"
      emptyColor="mandala.black.300"
      mb="40px"
      {...spinnerProps}
    />
    <Text color="mandala.blue.500" fontSize="xl">
      {message}
    </Text>
  </Flex>
);
export { _Spinner, _SpinnerText };

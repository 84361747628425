import React from "react";
import { USAGE_TRACKING_MAP } from "../";
import { UsageSummaryWrapper } from "./UsageSummaryWrapper";

export const UsageSummaryTrackerComponent = ({ quota }) => {
  const {
    storage: { limit: storageLimit, usage: storageUsage },
    processing: { limit: processingTimeLimit, usage: processingTimeUsage }
  } = quota;

  // Calculate limit and usage for percentage
  const storagePercentage = (100 * storageUsage) / storageLimit;
  const processingTimePercentage =
    (100 * processingTimeUsage) / processingTimeLimit;

  return (
    <React.Fragment>
      <UsageSummaryWrapper
        type={USAGE_TRACKING_MAP.STORAGE}
        progressValue={storagePercentage}
        usage={storageUsage}
        limit={storageLimit}
      />
      <UsageSummaryWrapper
        type={USAGE_TRACKING_MAP.PROCESSING}
        progressValue={processingTimePercentage}
        usage={processingTimeUsage}
        limit={processingTimeLimit}
      />
    </React.Fragment>
  );
};

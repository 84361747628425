import { REACT_QUERY_STATUS } from "src/constants/reactQueryStatus";
import { useFetchUserStatusQuery } from "./queries/fetches/useFetchUserStatusQuery";

/**
 * This hook will handle everything that related to user status
 * (e.g: isCDPUser and isFreeUser) by fetching into GET /users/status.
 * @returns {*}
 */

// Notes: on we implement SSR Fetch Data the result of "_getUserStatus" should be passed as a props and then we will store it on localstorage
export const useUserStatus = shouldFetchUserStatus => {
  const {
    data: userStatusData = {
      tier: null,
      isFreeUser: true,
      isCDPUser: false,
      quotaInformation: {
        storage: {},
        processing: {}
      }
    },
    status: userStatusRequestStatus
  } = useFetchUserStatusQuery({
    config: {
      enabled: shouldFetchUserStatus
    }
  });

  const isUserInformationStatusLoading =
    userStatusRequestStatus === REACT_QUERY_STATUS.LOADING;
  const isUserInformationStatusFetched =
    userStatusRequestStatus === REACT_QUERY_STATUS.SUCCESS;

  return {
    userStatusData,
    isUserInformationStatusLoading,
    isUserInformationStatusFetched
  };
};

import React from "react";

/**
 * Modal to handle the state of a modal
 */
const useModal = (defaultState = false, toggleCallback) => {
  const [isOpen, _setIsOpen] = React.useState(defaultState);

  const toggleModal = () => {
    _setIsOpen(state => {
      return !state;
    });
    if (toggleCallback) {
      toggleCallback();
    }
  };

  return { isOpen, toggleModal };
};

export default useModal;

import React from "react";
import { Flex, Icon, Text, chakra } from "@chakra-ui/react";
import { Banner } from "src/components/MandalaUI/Banner";
import { useUserStatusContext } from "src/contexts/users/userStatus";
import { RiErrorWarningFill } from "react-icons/ri";

export const LimitQuotaBanner = ({ ...props }) => {
  const {
    userStatusData: { isFreeUser, isQuotaExceeded }
  } = useUserStatusContext();

  const hideLimitQuotaBanner = !isFreeUser || (isFreeUser && !isQuotaExceeded);

  if (hideLimitQuotaBanner) {
    return null;
  }

  return (
    <Banner bg="#F13030" px="1.5em" {...props}>
      <Flex align="center" h="100%" color="white">
        <Icon as={RiErrorWarningFill} mr="1em" boxSize={6} />
        <Text color="white">
          This account has
          <chakra.span fontWeight="bold" ml=".25em">
            exceeded Free Tier Processing limit
          </chakra.span>
        </Text>
      </Flex>
    </Banner>
  );
};

export const PAGE_WITHOUT_NAVBAR_LIST = [
  "/",
  "/signup",
  "/verify-account/[userId]",
  "/forgot-password"
];

export const MAIN_LAYOUT = {
  OVERLAY_MODE_WIDTH: 1124,
  NAVBAR_HEIGHT: "3.5em",
  NAVBAR_COLLAPSED_HEIGHT: "0em",
  BANNER_HEIGHT: "2.875em",
  BANNER_COLLAPSED_HEIGHT: "0em",
  SIDEBAR_EXPANDED_WIDTH: "300px",
  SIDEBAR_COLLAPSED_WIDTH: "45px"
};

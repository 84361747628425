import isNumber from "lodash/isNumber";

const sizeType = ["B", "KB", "MB", "GB", "TB"];

const _calculateSize = input => {
  if (input < 1024) {
    return input;
  }

  return _calculateSize(input / 1024);
};

const _defineSizeType = input => Math.floor(Math.log(input) / Math.log(1024));

/**
 *
 * @param {number} input - The input file size in bytes.
 * @param {Object} config - The configuration object.
 * @param {number} [config.fixed=2] - The number of decimal points to round the file size to.
 *
 * @returns {string} The file size in the format `X.XXB`, `X.XXKB`, `X.XXMB`, `X.XXGB`, or `X.XXTB`
 *
 * @example
 * getFileSize(1024); // "1.00KB"
 * getFileSize(1048576); // "1.00MB"
 * getFileSize(10485760); // "10.00MB"
 * getFileSize(1073741824); // "1.00GB"
 * getFileSize(1099511627776); // "1.00TB"
 */
export const getFileSize = (input, config = {}) => {
  if (input === 0 || !input) {
    return "0B";
  }
  const fileSize = _calculateSize(input);
  const measurement = _defineSizeType(input);

  const configFixed = isNumber(config.fixed) ? config.fixed : 2;
  const fixedFileSize = fileSize.toFixed(configFixed);
  return fixedFileSize + sizeType[measurement];
};

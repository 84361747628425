import { secondsToMinutes } from "date-fns";
import { getFileSize } from "src/utils/fileSizeConverter";
import { USAGE_TRACKING_MAP } from "..";
import { UsageTrackingSummaryLabel } from "./ComponentWrapper/UsageTrackingSummaryLabel";
import isNull from "lodash/isNull";

// Wrapper for Progress Label, this is used for displaying text information below the progress bar
export const UsageSummaryLabelWrapper = ({ type, limit, color, usage }) => {
  const isSuperAdmin = isNull(limit);
  const isStorage = type === USAGE_TRACKING_MAP.STORAGE;

  const _generateLabelValue = () => {
    if (isStorage) {
      return getFileSize(usage, { fixed: 0 });
    }
    return `${secondsToMinutes(usage)} Mins`;
  };

  const value = _generateLabelValue();

  const _generateAdditionalLabel = () => {
    if (isStorage) {
      return `used of ${getFileSize(limit, { fixed: 0 })} Used.`;
    }

    return `used of ${secondsToMinutes(limit)} Mins Used.`;
  };

  const additionalLabel = isSuperAdmin ? "used" : _generateAdditionalLabel();

  return (
    <UsageTrackingSummaryLabel value={value} color={color}>
      {additionalLabel}
    </UsageTrackingSummaryLabel>
  );
};
